import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { graphql } from 'gatsby'
import PostIndex from '~components/PostIndex'
import DataViewer from '~utils/DataViewer'
import Seo from '~components/Seo'

const Resources = ({ data }) => {
	const page = data?.sanityResources
	return (
		<>	
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<PostIndex posts={data.resources?.nodes} filters={page.tags} title={page.title} rows={4} featurePost>
			</PostIndex>
			<DataViewer data={data} name="page"/>
		</>
	)
}

Resources.propTypes = {
	data: PropTypes.object
}

export const query = graphql`
	query ResourcesQuery {
		sanityResources{
			title {
				...multiString
			}
			_createdAt
			slug {
				current
			}
			tags {
				title {
				...multiString
				}
				slug {
					current
				}
			}
			seo {
				...seo
			}
		}
		resources: allSanityResource{
			nodes {
				...resourceThumb
			}
		}
	}
`

export default Resources